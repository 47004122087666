.navbar {
  margin-bottom: 0px;
  /* box-shadow: 0 1px 26px -2px rgba(2, 0, 0, 0.2); */
}
.navbar-default {
  border: 0px;
}
.logo {
  width: 161px;
  /* padding: 5px; */
}

.navbar-brand {
  padding: 0px;
}

.navbar-nav > li {
  font-size: 15px;
}

.navbar-default .navbar-nav > li > a {
  color: #0f363d;
  font-weight: bold;
}

.navbar-section {
  margin-top: 10px;
}

.navbar-default .navbar-toggle {
  border-color: #80808063;
}
